<template>
  <div>

    <v-checkbox
      v-model="form.published"
      :label="form.published ? 'Опубликован' : 'Скрыт'"
      dense
      hide-details
    />

    <v-row>
      <v-col>
        <validation-provider slim rules="required" name="url" v-slot="{ errors }">
          <v-text-field
            name="url"
            v-model="form.url"
            :error-messages="errors[0]"
            label="Url"
            hide-details
            outlined
            dense
          />
        </validation-provider>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-text-field
          v-model="form.navigationPath"
          name="navigationPath"
          label="navigationPath"
          hide-details
          outlined
          dense
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <validation-provider slim rules="required" name="label" v-slot="{ errors }">
          <v-text-field
            name="label"
            v-model="form.label"
            :error-messages="errors[0]"
            label="Метка для трекинга"
            hide-details
            outlined
            dense
          />
        </validation-provider>
      </v-col>
      <v-col>
        <validation-provider slim rules="required" name="theme" v-slot="{ errors }">
          <v-select
            :items="themesList"
            item-text="name"
            item-value="value"
            name="theme"
            v-model="form.theme"
            :error-messages="errors[0]"
            label="Тема"
            hide-details
            dense
            outlined
          />
        </validation-provider>
      </v-col>
    </v-row>

    <div class="text-h5">
      Desktop
    </div>
    <v-row>
      <v-col cols="3">
        <image-select-new
          :src="form.imageUrl"
          @input="onImageSelect"
          :ratio="1"
        />
      </v-col>
      <v-col>
        <locale-field
          component="v-text-field"
          name="title"
          placeholder="Title"
          hide-details
          v-model="form.title"
          class="mb-8"
        />
      </v-col>
    </v-row>

    <div class="text-h5 mt-8">
      Mobile
    </div>
    <v-row>
      <v-col cols="3">
        <image-select-new
          :src="form.mobileImageUrl"
          @input="onMobileImageSelect"
          :ratio="1"
        />
      </v-col>
      <v-col>
        <locale-field
          component="v-text-field"
          name="mobileTitle"
          placeholder="Mobile title"
          hide-details
          v-model="form.mobileTitle"
          class="mb-8"
        />
      </v-col>
    </v-row>

    <div class="text-h5 mt-8">
      Additional text
    </div>

    <locale-field
      component="v-text-field"
      name="text"
      placeholder="Text"
      hide-details
      v-model="form.text"
      class="mb-8"
    />

  </div>
</template>

<script>
import isEqual from 'lodash/isEqual'
import BannerSlide, { themes, THEME_DARK, THEME_LIGHT } from '@/models/BannerSlide'
import { views, VIEW_ROW, VIEW_GRID } from '@/models/Banner'
import LocaleField from '@/components/Locale/LocaleField.vue'
import imageSelectNew from '@/components/elements/image-select-new.vue'
import { createEnumProp } from '@/utils/props'

const themesExtra = {
  [THEME_DARK]: 'Темная',
  [THEME_LIGHT]: 'Светлая'
}

const ratioExtras = {
  [VIEW_ROW]: {
    mobile: '',
    desktop: '',
  },
  [VIEW_GRID]: {
    mobile: '',
    desktop: ''
  }
}

const themesList = Object
  .entries(themes)
  .map(([ name, value ]) => ({
    name: themesExtra[value] || name,
    value
  }))

export default {
  components: {
    LocaleField,
    imageSelectNew,
  },
  props: {
    value: {
      type: Object
    },
    view: createEnumProp({
      required: true,
      values: views,
    })
  },
  data () {
    return {
      themesList,
      form: new BannerSlide(this.value)
    }
  },
  watch: {
    form: {
      deep: true,
      handler (form) {
        this.$emit('input', form)
      }
    },
    value: {
      deep: true,
      handler (value) {
        const bannerSlide = new BannerSlide(value)
        if (isEqual(bannerSlide, this.form)) return
        this.form = bannerSlide
      }
    }
  },
  methods: {
    onImageSelect (image) {
      if (!image) return
      this.form.imageUrl = image.url
      this.form.imageId = image.id
    },
    onMobileImageSelect (image) {
      if (!image) return
      this.form.mobileImageUrl = image.url
      this.form.mobileImageId = image.id
    }
  }
}
</script>
